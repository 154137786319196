import { getAuthLoading, getAuthSuccess, getAuthFailure } from './reducers';
import AxiosUtils from '../../../utils/axios-utils';
import { AppDispatch } from '../../../types/store';
import { API } from '../../api-route';
import { setSession } from '../../../utils/session';
import { paths } from '../../../utils/common';

export const getToken = (navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAuthLoading());
    const res: any = await AxiosUtils.post(API.getToken, {
      secureCode: process.env.REACT_APP_SECURE_CODE,
    }, {
      'Content-Type': 'application/json'
    });
    const token = res?.data.data;
    dispatch(getAuthSuccess(token));
    setSession(token);
    navigate(paths.home);
  } catch (error: any) {
    if (typeof(error) === 'string') {
      dispatch(getAuthFailure(error));
    } else {
      dispatch(getAuthFailure('Can not login'));
    }
  }
};