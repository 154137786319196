import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import {store, persistor} from './store/configure-store';
import { ANTD_THEME_CONFIG } from './utils/constant';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider theme={ANTD_THEME_CONFIG}>
      <PersistGate loading={<div />} persistor={persistor}>
        <BrowserRouter>
          <ToastContainer autoClose={2000}/>
          <App />
        </BrowserRouter>
      </PersistGate>
    </ConfigProvider>
  </Provider>
);
