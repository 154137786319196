import React, { ReactElement } from "react";

type Props = {
  children: ReactElement | ReactElement[];
  sectionTitle: string;
  className?: string;
}

const FormSectionLayout: React.FC<Props> = ({ children, sectionTitle, className }) => {
  return (
    <div className={"overlay-gray rounded-xl p-4 sm:p-4 md:p-6 relative " + className } >
      <div className="text-xl font-semi-bold text-gray-1">
        {
          sectionTitle
        }
      </div>
      <div className="mt-5">
        {
          children
        }
      </div>
    </div>
  )
};

export default FormSectionLayout;