import { getIdTypeFailure, getIdTypeSuccess, getIdTypelLoading } from './reducers';
import { AppDispatch } from '../../../types/store';
import AxiosUtils from '../../../utils/axios-utils';
import { API } from '../../api-route';

export const fetchIdType = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getIdTypelLoading());
    const res: any = await AxiosUtils.get(API.idType);
    const newArray = res.data?.data?.values?.map((item: string) => {
      return {
        value: item,
        label: item,
      }
    });
    dispatch(getIdTypeSuccess(newArray));
  } catch (error: any) {
    dispatch(getIdTypeFailure(error));
  }
};