import { Input, InputProps, InputRef } from 'antd';
import React from 'react';

export type BaseInputRef = InputRef;

export type BaseInputProps = InputProps;

interface BaseInputInterface
  extends React.ForwardRefExoticComponent<InputProps & React.RefAttributes<InputRef>> {
  Group: typeof Input.Group;
  Search: typeof Input.Search;
  TextArea: typeof Input.TextArea;
  Password: typeof Input.Password;
}

const InternalInput: React.FC<BaseInputProps> = ({ className, children, ...props }, ref) => {
  return (
    <div className='relative'>
      <Input ref={ref} className={`${className}`} {...props} placeholder=''>
        {children}
      </Input>
      {/* <div className='absolute top-[-12px] left-3 bg-main-theme px-2 text-white rounded'>{props.placeholder}</div> */}
    </div>
  )
}

const PassInternal: React.FC<BaseInputProps> = ({ className, children, ...props }, ref) => {
  return (
    <div className='relative'>
      <Input.Password ref={ref} className={`${className}`} {...props} placeholder=''>
        {children}
      </Input.Password>
    </div>
  )
}

export const BaseInput = InternalInput as BaseInputInterface;
export const PasswordInput = PassInternal;
BaseInput.Group = Input.Group;
BaseInput.Search = Input.Search;
BaseInput.TextArea = Input.TextArea;
