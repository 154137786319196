import React from "react";

type MyMessageProps = {
  message: string;
}

const MyMessage: React.FC<MyMessageProps> = (props) => {
  const {message} = props;

  return (    
    <div className="gap-2 max-sm:pl-11 flex justify-end mb-10">
      <div className="max-sm:w-[100%] w-[60%] flex justify-end">
        <div className="mt-4 bg-main-theme px-5 py-2 text-white rounded-tl-3xl rounded-tr-sm rounded-br-3xl rounded-bl-3xl break-all text-base">
          <div style={{ whiteSpace: 'pre-line' }}>
            {message}
          </div>
        </div>
      </div>
      <div className="h-s-40 w-s-40 rounded-ful flex items-center bg-pink-400 rounded-full justify-center">
        <p className="w-s-60 text-center text-white uppercase">
          {/* {userDetail?.name?.charAt(0)} */} U
        </p>
      </div>
    </div>
  )
};

export default MyMessage