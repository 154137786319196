import { StorageKey, StorageUtils } from './local-storage';
import { Session } from '../types/common';

// set session to local
export const setSession = (session: Session | null) => {
  StorageUtils.saveObject(StorageKey.SESSION, session);
};

// get
export const getSession = () => {
  const session = StorageUtils.getObject(StorageKey.SESSION);
  if (session) {
    return session;
  } else {
    localStorage.removeItem(StorageKey.SESSION);
  }

  setSession(null);
  localStorage.removeItem(StorageKey.SESSION);
  return null;
};

export const deleteSession = () => {
  StorageUtils.saveObject(StorageKey.SESSION, {});
};
