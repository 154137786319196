import React from "react";
import Logo from "../logo";

type Props = {
  children: React.ReactElement | React.ReactElement[];
}

const AuthenLayout: React.FC<Props> = ({children}) => {
  return (
    <div className="w-screen h-screen gradient-theme flex items-center flex-col overflow-y-auto pb-12">
      <div className="mt-s-10 sm:mt-s-10 md:mt-s-40 mb-s-20 sm:mb-s-20 md:mb-s-30">
        <Logo fontSize="text-h5 sm:text-h5 md:text-h4" />
      </div>
      {children}
    </div>
  )
};

export default AuthenLayout;