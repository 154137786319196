import React, { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Session } from '../types/common';
import { getSession } from '../utils/session';
import { paths } from '../utils/common';

interface Props {
  children: ReactElement;
}

const ProtectedRoute = ({ children }: Props) => {
  // const dispatch = useAppDisPatch();
  const session: Session = getSession();

  useEffect(() => {
  const session: Session = getSession();
    if (session?.access_token) {
      // dispatch(getUserDetail());
    } 
  }, [])  

  if (!session || !session.access_token) {
    return <Navigate to={paths.getStart} />;
  }

  return children;
};

export default ProtectedRoute;