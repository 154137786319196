export const API = {
  // auth
  getToken: '/token',
  signUpAtlas: '/users/signup',
  getSingpass: '/users/getSingpass',

  // chat
  sendMessage: '/web_completion',

  // user
  userDetail: '/users/me',
  updateUser: '/users/me',

  // common
  idType: '/users/id_type_list',
  countryList: '/users/country_list',
  occupationList: '/users/occupation_list',
  sourceOfFundList: '/users/source_of_fund_list',

  // singpass
  singpassGetLoginUrl: '/singpass/get_login_url',
  singpassMockData: '/singpass/sample'
};