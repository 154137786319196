import { faFacebook, faLinkedinIn, faRedditAlien, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";

type Props = {
  isOpenShareModal: boolean;
  activeConver?: string | null;
}

const BASE_URL = process.env.REACT_APP_FRONT_END_BASE_URL ?? 'https://remitai-demo.dopik.ai/';

const ShareModal: React.FC<Props> = ({isOpenShareModal, activeConver}) => {
  const [isCreateLinkLoading, setIsCreateLinkLoading] = useState<boolean>(false);
  const [createdLink, setCreatedLink] = useState<string | null>(null);

  const onCreateLink = () => {
    setIsCreateLinkLoading(true);
    setTimeout(() => {
      setIsCreateLinkLoading(false);
      const createdL = (BASE_URL) + 'share/' + activeConver
      setCreatedLink(createdL)
    }, 2000);
  }

  return (
    <>
      <div className="font-roboto">
          <h2 className="text-[16px] font-medium text-gray-1">Share public link to chat</h2>
          <Divider className="my-5" />
          <p className="text-[14px] mt-5 mb-5 text-gray-1">
            Any messages you add after sharing stay private.
          </p>
          {
              createdLink ? (
                <>
                  <div className="border mt-2 rounded-md p-3 flex items-center justify-between gap-2">
                    <p className="truncate ... text-gray-2 text-[17px]">{BASE_URL}share...</p>
                    <Button
                      loading={isCreateLinkLoading}
                      type='primary'
                      className="bg-main-theme text-white font-bold mt-2 text-[16px] py-2 h-auto"
                      onClick={() => {
                        toast.success('Copy link to clipboard')
                        navigator.clipboard.writeText(createdLink);
                      }}
                    >Copy</Button>
                  </div>
                  <div className="flex px-8 justify-between mt-8">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://www.linkedin.com/shareArticle?url=' + createdLink}
                      className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-full hover:bg-[#0077b5] hover:text-[white] cursor-pointer text-lg"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://www.facebook.com/sharer/sharer.php?u=' + createdLink}
                      className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-full hover:text-[white] cursor-pointer text-lg hover:bg-[#0866ff]"
                    >
                      <FontAwesomeIcon icon={faFacebook} />  
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://www.reddit.com/submit?url=' + createdLink}
                      className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-full hover:text-[white] cursor-pointer text-lg hover:bg-[#ff4500]"
                    >
                      <FontAwesomeIcon icon={faRedditAlien} />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                        href={'https://twitter.com/intent/tweet?url=' + createdLink}
                      className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-full hover:text-[white] cursor-pointer text-lg hover:bg-[#000000]"
                    >
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="border mt-2 rounded-md p-3 flex items-center justify-between gap-2">
                    <p className="truncate ... text-gray-2 text-[17px]">{BASE_URL}share...</p>
                    <Button loading={isCreateLinkLoading} onClick={onCreateLink} type='primary' className="bg-main-theme text-white font-bold mt-2 text-[16px] py-2 h-auto">Create link</Button>
                  </div>
                </>
              )
            }
        </div>
    </>
  )
};

export default ShareModal;