import { checkDOB, checkIDExpiry, checkNumber, checkPassword, checkPhoneNumber, checkEmail } from "./common";
import { REQUIRED_MESSAGE } from "./constant";

const REQUIRED = { required: true, message: REQUIRED_MESSAGE };
const CHECK_PASSWORD = { validator: checkPassword };
const CHECK_NUMBER = { validator: checkNumber };
const CHECK_DOB = { validator: checkDOB };
const CHECK_ID_EXPIRY = { validator: checkIDExpiry };
const CHECK_PHONE_NUMBER = { validator: checkPhoneNumber };
const CHECK_EMAIL = { validator: checkEmail }

export { REQUIRED, CHECK_PASSWORD, CHECK_NUMBER, CHECK_DOB, CHECK_ID_EXPIRY, CHECK_PHONE_NUMBER, CHECK_EMAIL }