import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { faUpLong } from "@fortawesome/free-solid-svg-icons";
import { QUICK_QUESTIONS } from "../../../utils/constant";

type Props = {
  messages: any;
  chatText: string;
  onChangeChatText: (text: string) => void;
  onSendMessage: (text: string) => void;
  isLoading: boolean;
}

const ChatBox: React.FC<Props> = ({messages, chatText, onChangeChatText, isLoading, onSendMessage}) => {
  const sendButtonRef = useRef<any>(null);
  const handleSendMessage = async () => {
    if (chatText?.trim()?.length && !isLoading) {
      onSendMessage(chatText);
    }
  };

  return (
    <div className="flex justify-center px-12 w-full">
      <div className="flex-1">
        {
          !messages.length ? (
            <div className="flex justify-center">
              <div className="max-w-[700px] mb-4">
                <div className="flex items-center justify-center gap-3 flex-wrap">
                  {
                    QUICK_QUESTIONS.map((question, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            animation: `fade-in ${300 + index * 150}ms ease-in-out`,
                          }}
                        >
                          <Button
                            key={index}
                            type='dashed'
                            className={`h-auto text-left p-3 w-[320px]`}
                            
                            onClick={() => onSendMessage(question.title)}
                          >
                            <div className="text-black text-base font-medium">
                              {question.title}
                            </div>
                            <p className="text-gray-1 text-base">
                              {question.subTitle}
                            </p>
                          </Button>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          ) : null
        }
        <div className="flex items-end justify-between border bg-white border-slate-300 rounded-lg min-w-[320px] max-w-[900px] mx-auto">
          <textarea
            className="outline-none border-none m-0 w-full text-base resize-none border-0 bg-transparent focus:ring-0 focus-visible:ring-0 dark:bg-transparent max-h-25 py-[10px] pr-10 md:py-3.5 md:pr-12 placeholder-black/50 dark:placeholder-white/50 pl-3 md:pl-4"
            placeholder="Message Chatbot..."
            value={chatText}
            onChange={(e) => onChangeChatText(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                sendButtonRef.current.click();
              }
            }}
          />
          <div className="p-4">
            <Button
              ref={sendButtonRef}
              disabled={isLoading || !chatText.length}
              type='primary'
              className={`send-btn ${
                isLoading || !chatText.length ? "" : "active"
              } h-s-40 w-s-40 p-0 bg-main-theme`}
              onClick={() => handleSendMessage()}
              >
              <FontAwesomeIcon icon={faUpLong} className="text-md" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ChatBox;