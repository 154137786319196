import { createSlice } from '@reduxjs/toolkit';
import { IUserDetailStore } from '../../../types/user';

const initialState: IUserDetailStore = {
  data: null,
  isLoading: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'userDetail',
  initialState: initialState,
  reducers: {
    getUserDetailLoading: (state) => {
      state.isLoading = true;
    },
    getUserDetailsSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getUserDetailFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetUserDetail: (state) => {
      state.data = null;
      state.error = '';
      state.isLoading = false;
    }
  },
});

export const { getUserDetailFailure, getUserDetailLoading, getUserDetailsSuccess, resetUserDetail } =
  sessionSlice.actions;

export default sessionSlice.reducer;