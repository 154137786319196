import { getLoginUrlFailure, getLoginUrlLoading, getLoginUrlSuccess } from './reducers';
import AxiosUtils from '../../../utils/axios-utils';
import { AppDispatch } from '@/types/store';

export const fetchLoginUrl = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getLoginUrlLoading());
    const res: any = await AxiosUtils.post(`${process.env.REACT_APP_BE_UI_BASE_URL}getAuthorizeUrl`);
    dispatch(getLoginUrlSuccess(res?.data));
  } catch (error: any) {
    dispatch(getLoginUrlFailure(error));
  }
};