import { useState } from "react";


const useMutation = <T>({
  cb,
  onSuccess,
  onError,
}: {
  cb: (arg: T) => Promise<any>;
  onSuccess: (data: any, arg?: T) => void;
  onError: (error?: string | null, arg?: T) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [data, setData] = useState<T>();
  const controller = new AbortController();

  const mutateFn = (arg: T) => {
    setIsLoading(true);
    cb(arg)
      .then((data: any) => {
        if (!controller.signal.aborted) {
          setIsLoading(false);
          onSuccess(data, arg);
          setData(data);
        }
      })
      .catch((error: any) => {
        if (!controller.signal.aborted) {
          setIsLoading(false);
          setError(error);
          onError(error, arg);
        }
      });
  };

  const onCancel = () => {
    controller.abort();
  }

  return { isLoading, mutateFn, error, data, onCancel };
};

export { useMutation }

