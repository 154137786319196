import React from "react";

type Props = {
  fontSize?: string;
}

const Logo: React.FC<Props> = ({fontSize}) => {
  return (
    <span className="leading-tight text-center">
      <span className={`${fontSize ? `${fontSize}` : 'text-h4'} font-bold font-rale-way`}>remit</span>
      <span className={`${fontSize ? `${fontSize}` : 'text-h4'} font-bold font-rale-way text-gray-1`}>.</span>
      <span className={`${fontSize ? `${fontSize}` : 'text-h4'} font-bold text-pink-theme-1 font-rale-way`}>ai</span>
    </span>
  )
};

export default Logo;