import { createSlice } from '@reduxjs/toolkit';
import { IConversationsStore } from '../../../types/chat';

const initialState: IConversationsStore = {
  data: [],
  isLoading: false,
  error: '',
  active: null,
};

const sessionSlice = createSlice({
  name: 'conversations',
  initialState: initialState,
  reducers: {
    getConversationsLoading: (state) => {
      state.isLoading = true;
    },
    getConversationsSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getConversationsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addConversations: (state, action) => {
      state.data = [action.payload, ...state.data];
    },
    setConversations: (state, action) => {
      state.data = action.payload;
    },
    updateConversations: (state, action) => {
      const find = state.data?.find((c) => c.id === action.payload.id);
      if (find) {
        const newArray = [...state.data].map((item, index) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              messages: action.payload.messages,
            }
          } else {
            return item;
          }
        });
        state.data = newArray;
      } else {
        state.data = [action.payload, ...state.data];
      }
    },
    resetConversationsData: (state) => {
      state.isLoading = false;
      state.data = [];
      state.error = '';
    },
    updateTopic: (state, action) => {
      const find = state.data?.find((c) => c.id === action.payload.id);
      if (find) {
        const newArray = [...state.data].map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              topic: action.payload.topic,
            }
          } else {
            return item;
          }
        });
        state.data = newArray;
      } else {
        state.data = [action.payload, ...state.data];
      }
    },
    setActiveConversation: (state, action) => {
      state.active = action.payload.active
    },
    deleteConversation: (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload.id)
    }
  },
});

export const { deleteConversation, getConversationsFailure, getConversationsLoading, getConversationsSuccess, resetConversationsData, setConversations, addConversations, updateConversations, updateTopic} =
  sessionSlice.actions;

export default sessionSlice.reducer;