import React, { useEffect, useRef, useState } from "react";
import { IConversation, IMessageItem } from "../../../../types/chat";
import { Button, Dropdown, Input, Modal } from "antd";
import { faEllipsis, faArrowUpFromBracket, faPenFancy, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCheckClickOutside } from "../../../../hooks/useCheckClickOutSide";
import ShareModal from "./share-modal";

type Props = {
  conversation: IConversation,
  activeConver?: string | null,
  onDeleteConver: (id: string) => void; 
  changeActiveConver: (id: string, messages: IMessageItem[]) => void;
  onUpdateTopic: (id: string, topic: string) => void;
}

const Conversation: React.FC<Props> = ({ conversation, activeConver, onDeleteConver, changeActiveConver, onUpdateTopic }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [isEditTopic, setIsEditTopic] = useState<boolean>(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);  
  const dropDownRef = useRef(null);
  const [topic, setTopic] = useState<string>(conversation.topic);
  const [isOpenShareModal, setIsOpenShareModal] = useState<boolean>(false);

  const onChangeTopic = () => {
    setIsEditTopic(false);
    onUpdateTopic(conversation.id, topic.length ? topic : 'Untitled');
    if (!topic.length) setTopic('Untitled');
  }

  useCheckClickOutside((target: any) => {
    const current = dropDownRef?.current as any; 
    if (current && !current?.contains(target) && isEditTopic) {
      onChangeTopic();
    }
  }, containerRef, [topic, isEditTopic]);

  useEffect(() => {
    setTopic(conversation.topic)
  }, [conversation.topic])


  useEffect(() => {
    if (isEditTopic) {
      const current = inputRef.current as any;
      current?.focus();
    }
  }, [isEditTopic]);

  return (
    <div
      className={`relative text-base ${conversation.id === activeConver ? 'bg-white text-pink-theme-1' : '' } cursor-pointer p-1 text-gray-800 hover:bg-white hover:text-pink-theme-1 rounded-md duration-300`}
    >
      <div ref={containerRef} className={`${conversation.id === activeConver ? 'h-6 mr-s-30' : ''}`} onClick={() => changeActiveConver(conversation.id, conversation.messages)}>
        {
          isEditTopic ? (
            <Input
              ref={inputRef}
              value={topic}
              onChange={(v) => setTopic(v.target.value)}
              className="h-6 p-0 text-base border-none outline-none"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onChangeTopic();
                }
              }}
            />
          ) : (
            <p className="w-full h-6 truncate ...">
              {topic}
            </p>
          )
        }
      </div>
      {
        conversation.id === activeConver ? (
          <Dropdown 
            trigger={['click']}
            placement="bottomRight"
            onOpenChange={setIsOpenDropdown}
            dropdownRender={() => {
              return (
                <div className="bg-white p-2 rounded-md w-[120px]" ref={dropDownRef}>
                  <Button className="p-2 h-auto border-none w-full text-left text-gray-700" type='text' onClick={() => {
                    setIsOpenShareModal(true);
                    setIsOpenDropdown(false);
                  }}>
                    <FontAwesomeIcon icon={faArrowUpFromBracket} className="me-2 text-base w-s-20" />
                    Share
                  </Button>
                  <Button className="p-2 h-auto border-none w-full text-left text-gray-700" type='text' onClick={() => {
                    setIsEditTopic(true);
                  }}>
                    <FontAwesomeIcon icon={faPenFancy} className="me-2 text-base w-s-20" ></FontAwesomeIcon>
                    Rename
                  </Button>
                  <Button className="p-2 h-auto border-none w-full text-left !text-pink-theme-1" type='text' onClick={() => onDeleteConver(conversation.id)}>
                    <FontAwesomeIcon icon={faTrashCan} className="me-2 text-base w-s-20" />
                    Delete
                  </Button>
                </div>
              )
            }}
          >
            <div className="absolute h-full top-0 right-0 conversation-more w-[50px] rounded-[12px]">
              <div className={`h-full !bg-none w-full flex justify-center items-center hover:!text-black ms-1 ${isOpenDropdown ? 'text-black' : 'text-gray-700'}`}>
                <FontAwesomeIcon icon={faEllipsis} />
              </div>
            </div>
          </Dropdown>
        ) : null
      }

    <Modal
        styles={{
          body: {
            padding: '12px 4px'
          }
        }}
        open={isOpenShareModal}
        onOk={() => setIsOpenShareModal(false)}
        onCancel={() => setIsOpenShareModal(false)}
        centered
        footer={null}
        destroyOnClose={true}
      >
        <ShareModal isOpenShareModal={isOpenShareModal} activeConver={activeConver} />
      </Modal>
    </div>
  )
};

export default Conversation