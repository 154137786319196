import { useEffect } from 'react';

export const useCheckClickOutside = (checkFunction: any, ref: any, dependencies: any[]) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref.current?.contains(event.target)) {
        checkFunction(event?.target);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...dependencies]);
};
