import { createSlice } from '@reduxjs/toolkit';
import { IMessagesStore } from '../../../types/chat';

const initialState: IMessagesStore = {
  data: [],
  isLoading: false,
  error: '',
  activeConversation: null,
};

const sessionSlice = createSlice({
  name: 'messages',
  initialState: initialState,
  reducers: {
    getMessagesLoading: (state) => {
      state.isLoading = true;
    },
    getMessagesSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getMessagesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    addMessages: (state, action) => {
      state.data = [...state.data, ...action.payload];
    },
    setMessages: (state, action) => {
      state.data = action.payload;
    },
    updateMessages: (state, action) => {
      const newArray = [...state.data];
      newArray[action.payload?.index] = {
        ...newArray[action.payload?.index],
        text: action.payload?.text,
        isGenerated: action.payload?.isGenerated,
        isError: action.payload?.isError,
      };
      state.data = newArray;
    },
    resetMessagesData: (state) => {
      state.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-array-constructor
      state.data = new Array();
      state.error = '';
    },
  },
});

export const { getMessagesFailure, getMessagesLoading, getMessagesSuccess, resetMessagesData, setMessages, addMessages, updateMessages} =
  sessionSlice.actions;

export default sessionSlice.reducer;