import { getSignUpFailure, getSignUpLoading, getSignUpSuccess } from './reducers';
import AxiosUtils from '../../../utils/axios-utils';
import { AppDispatch } from '../../../types/store';
import { API } from '../../api-route';
import { ISignUpAccount } from '../../../types/user';
import { toast } from 'react-toastify';

export const signUp = (account: ISignUpAccount) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getSignUpLoading());
    const res: any = await AxiosUtils.post(API.signUpAtlas, account, {
      'Content-Type': 'application/json',
    });
    const data = res.data.data;
    dispatch(getSignUpSuccess(data));
    toast.success('Create customer successfully');
  } catch (error: any) {
    if (typeof(error) === 'string') {
      try {
        const objectErr = JSON.parse(error);
        dispatch(getSignUpFailure(objectErr.data.message));
      } catch (err) {
        dispatch(getSignUpFailure('Account creation failed'));
      }
    } else {
      dispatch(getSignUpFailure('Account creation failed'));
    }
  }
};
