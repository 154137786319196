import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  isLoading: false,
  error: '',
  isSuccess: null,
} as any;

const sessionSlice = createSlice({
  name: 'signUp',
  initialState: initialState,
  reducers: {
    getSignUpLoading: (state) => {
      state.isLoading = true;
    },
    getSignUpSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isSuccess = true;
      state.isLoading = false;
    },
    getSignUpFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isSuccess = false;
    },
    resetSignUpData: (state, action) => {
      state.isLoading = false;
      state.data = null;
      state.error = '';
      state.isSuccess = null;
    },
  },
});

export const { getSignUpFailure, getSignUpLoading, getSignUpSuccess, resetSignUpData} =
  sessionSlice.actions;

export default sessionSlice.reducer;