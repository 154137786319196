import { ISingPassData } from '@/types/singpass';
import { createSlice } from '@reduxjs/toolkit';

interface IState {
  data: ISingPassData | null;
  isLoading: boolean;
  error: string;
}

const initialState: IState = {
  data: null,
  isLoading: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'get-sample-data',
  initialState: initialState,
  reducers: {
    getSampleDataLoading: (state, action) => {
      state.isLoading = action?.payload;
    },
    getSampleDataSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getSampleDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSampleData: (state) => {
      state.data = null;
      state.error = '';
      state.isLoading = false;
    }
  },
});

export const { getSampleDataFailure, getSampleDataLoading, getSampleDataSuccess, resetSampleData } =
  sessionSlice.actions;

export default sessionSlice.reducer;