import React from "react";

const MessageLoading = () => {
  return (
    <div className="mt-4 bg-gray-100 px-5 py-2 text-white rounded-tl-sm rounded-tr-3xl rounded-br-3xl rounded-bl-3xl">
      <React.Fragment>
        <span className='typing-dot'></span>
        <span className='typing-dot'></span>
        <span className='typing-dot'></span>
      </React.Fragment>
    </div>
  )
};

export default MessageLoading;