import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'sourceOfFundList',
  initialState: initialState,
  reducers: {
    getSourceOfFundListLoading: (state) => {
      state.isLoading = true;
    },
    getSourceOfFundListSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getSourceOfFundListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSourceOfFundList: (state) => {
      state.data = [];
      state.error = '';
      state.isLoading = false;
    }
  },
});

export const { getSourceOfFundListSuccess, getSourceOfFundListFailure, getSourceOfFundListLoading, resetSourceOfFundList } =
  sessionSlice.actions;

export default sessionSlice.reducer;