import { getOccupationListFailure, getOccupationListLoading, getOccupationListSuccess } from './reducers';
import { AppDispatch } from '../../../types/store';
import AxiosUtils from '../../../utils/axios-utils';
import { API } from '../../api-route';

export const fetchOccupationList = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getOccupationListLoading());
    const res: any = await AxiosUtils.get(API.occupationList);
    const newArray = res.data?.data?.values?.map((item: string) => {
      return {
        value: item,
        label: item,
      }
    });
    dispatch(getOccupationListSuccess(newArray));
  } catch (error: any) {
    dispatch(getOccupationListFailure(error));
  }
};