import React from "react";
import { Button, Divider } from "antd";
import { faPenToSquare, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  isOpenSideBar: boolean;
  reset: () => void;
  toggleDrawer?: () => void;
}

const TopBar: React.FC<Props> = ({isOpenSideBar, reset, toggleDrawer}) => {
  return (
    <>
      <div className='px-2 sm:max-md:px-2 lg:px-4 py-2 flex gap-2 w-full justify-between sm:max-md:justify-between lg:justify-start'>
        {
          !isOpenSideBar && (
            <Button className="max-lg:hidden xl:block h-s-40 w-s-40 p-0 flex justify-center items-center" onClick={() => reset()}>
              <FontAwesomeIcon icon={faPenToSquare} className="text-lg" />
            </Button>
          )
        }
        <div>
          <Button className="flex sm:max-md:flex lg:hidden h-s-40 w-s-40 p-0 justify-center items-center" onClick={() => {
            toggleDrawer && toggleDrawer()
          }}>
            <FontAwesomeIcon icon={faBars} className="text-lg" />
          </Button>
        </div>
        <div className="font-medium text-2xl text-black leading-[40px]">Chatbot</div>
        <div>
          <Button className="flex sm:max-md:flex lg:hidden h-s-40 w-s-40 p-0 justify-center items-center" onClick={() => reset()}>
            <FontAwesomeIcon icon={faPenToSquare} className="text-lg" />
          </Button>
        </div>
      </div>
      <Divider className="my-2 flex sm:max-md:flex lg:hidden"/>
    </>
  )
};

export default TopBar;