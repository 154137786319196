import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  isLoading: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'sendMessage',
  initialState: initialState,
  reducers: {
    getSendMessageLoading: (state) => {
      state.isLoading = true;
    },
    getSendMessageSuccess: (state?) => {
      state.isLoading = false;
    },
    getSendMessageFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSendMessageData: (state) => {
      state.isLoading = false;
      state.data = null;
      state.error = '';
    },
  },
});

export const { getSendMessageFailure, getSendMessageLoading, getSendMessageSuccess, resetSendMessageData,} =
  sessionSlice.actions;

export default sessionSlice.reducer;