import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  isLoading: false,
  isCalled: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'getToken',
  initialState: initialState,
  reducers: {
    getAuthLoading: (state) => {
      state.isLoading = true;
    },
    getAuthSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
      state.isCalled = true;
    },
    getAuthFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isCalled = true;
    },
    removeAuth: (state) => {
      state.isLoading = false;
      state.error = '';
    },
    changeTokenExpired: (state) => {
      state.data = null;
      state.error = '';
      state.isLoading = false;
    },
  },
});

export const { getAuthLoading, getAuthSuccess, getAuthFailure, removeAuth, changeTokenExpired } =
  sessionSlice.actions;

export default sessionSlice.reducer;