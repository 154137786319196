import { DatePicker } from 'antd';
import React, { ComponentProps } from 'react';

export interface BaseDatePickerProps extends ComponentProps<typeof DatePicker> {
  className?: string;
}

export const BaseDatePicker: React.FC<BaseDatePickerProps> = ({ className, children, ...props }) => (
  <div className='relative'>
    <DatePicker
        style={{
          backgroundColor: props.disabled ? 'rgba(59, 59, 59, 0.3)' : 'white',
        }}
        className={className}
        {...props}
      >
        {children}
      </DatePicker>
  </div>
);
