import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  error: '',
};

const sessionSlice = createSlice({
  name: 'idType',
  initialState: initialState,
  reducers: {
    getIdTypelLoading: (state) => {
      state.isLoading = true;
    },
    getIdTypeSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getIdTypeFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetIdType: (state) => {
      state.data = [];
      state.error = '';
      state.isLoading = false;
    }
  },
});

export const { getIdTypeSuccess, getIdTypeFailure, getIdTypelLoading, resetIdType } =
  sessionSlice.actions;

export default sessionSlice.reducer;