import { combineReducers } from 'redux';
import getTokenReducers from './auth/get-token/reducers';
import signUpReducers from './auth/sign-up/reducers';
import sendMessageReducers from './chat/send-message/reducers';
import messagesReducers from './chat/messages/reducers';
import conversationsReducers from './chat/conversations/reducers';
import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userDetailReducer from './user/user-detail/reducers';
import idTypeReducer from './common/id-type/reducers';
import countryListReducer from './common/country-list/reducers';
import updateUserReducer from './user/user-detail/reducers';
import occupationListReducer from './common/occupation-list/reducers';
import sourceOfFundListReducer from './common/source-of-fund-list/reducers';
import getLoginUrlReducer from './singpass/get-login-url/reducers';
import singpassMockDataReducer from './singpass/get-sample-data/reducers'

const conversationsPersist = {
  key: 'conversations',
  storage
}

const userPersist = {
  key: 'userDetail',
  storage
}

const rootReducer = combineReducers({
  getToken: getTokenReducers,
  signUp: signUpReducers,
  sendMessage: sendMessageReducers,
  messages: messagesReducers,
  conversations: persistReducer(conversationsPersist, conversationsReducers),
  userDetail: persistReducer(userPersist, userDetailReducer),
  idType: idTypeReducer,
  countryList: countryListReducer,
  updateUser: updateUserReducer,
  occupationList: occupationListReducer,
  sourceOfFundList: sourceOfFundListReducer,
  loginUrl: getLoginUrlReducer,
  singpassMockData: singpassMockDataReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
})

export const persistor = persistStore(store)
