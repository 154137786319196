
export enum StorageKey {
  AUTH = 'AUTH',
  SESSION = 'SESSION',
}

export class StorageUtils {
  static save(key: StorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  static get(key: StorageKey): string | null {
    return localStorage.getItem(key);
  }

  static remove(key: StorageKey): void {
    return localStorage.removeItem(key);
  }

  static getObject(key: StorageKey) {
    const value = localStorage?.getItem(key);
    if (value && value !== 'undefined') {
      const object = JSON?.parse(value);
      return object;
    }
    return null;
  }

  static saveObject<T>(key: StorageKey, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static clear(): void {
    localStorage.clear();
  }
}